import type { ClientInvestorMemberResponse_Success } from '@/types'

interface DeckUserStore  {
  deckmatchUser: {
    profile:ClientInvestorMemberResponse_Success,
    meta: {
      status: 'not_fetched' | 'fetching_get_error' | 'fetching_success' | 'fetching' | 'updating' | 'no_access'
      error?: {
        statusCode: 401 | 402 | 404 | 500,
        statusMessage: string
      }
    },
    profileStatus: { [key in 'user' | 'models' | 'industries' | 'countries'  | 'memo' ]: boolean }
  }
}

export const useDeckmatchUserStore = defineStore('deckmatchUser', {
 state: () => ({
   deckmatchUser: {
    profile: {},
    meta: {
      status: 'not_fetched',
      error: {},
    },
    profileStatus: {
      user: true,
      models: true,
      industries: true,
      countries: true,
      memo: true
    }
   } 
 } as DeckUserStore),
 getters: {
  getProfile: (state) => state.deckmatchUser.profile,
  getUserInfo:(state) => {
    const {first_name, last_name, email, phone_number } = state.deckmatchUser.profile
    return {
      first_name, last_name, email, phone_number
    }
  },
  getRules:(state) => {
    const {investment_business_models, investment_countries, investment_industries, investment_revenue_models, investment_stages, investment_technology_types, investment_thesis} = state.deckmatchUser.profile
    return {
      investment_business_models, investment_countries, investment_industries, investment_revenue_models, investment_stages, investment_technology_types, investment_thesis
    }
  },
  getMeta: (state) => state.deckmatchUser.meta,
  getProfileStatus: (state) => state.deckmatchUser.profileStatus 
 },
 actions: {
  resetError() {
    this.deckmatchUser.meta = {
      status: 'not_fetched',
    }
  },
  setProfileStatus(value :ClientInvestorMemberResponse_Success) {
    this.deckmatchUser.profileStatus = {
      user: (!!value.first_name && !!value.last_name) || false,
      models: (value.investment_business_models && value.investment_business_models.length > 0) && (value.investment_revenue_models && value.investment_revenue_models.length > 0) && (value.investment_stages && value.investment_stages.length > 0) && (value.investment_technology_types && value.investment_technology_types.length > 0) || false,
      industries: value.investment_industries && value.investment_industries.length > 0 || false,
      countries: value.investment_countries && value.investment_countries.length > 0 || false,
      memo: value.investment_thesis && value.investment_thesis.length > 0 || false
    }
  },
  async updateUser(changes:Partial<ClientInvestorMemberResponse_Success>){
    const { $auth0 } = useNuxtApp()
    const getTokenSilently = await $auth0.getTokenSilently()
    let res

    const savedSettings = {...this.deckmatchUser.profile}
    
    try {
      res = await $fetch<Promise<ClientInvestorMemberResponse_Success>>('/api/deckmatch_user', {
        headers: {
          authorization: `Bearer ${getTokenSilently}`
        },
        method: 'PUT',
        body: {
          ...savedSettings,
          ...changes
        }
      })
      
      this.deckmatchUser.profile = res
      this.setProfileStatus(res)
      this.deckmatchUser.meta = {
        status: 'fetching_success'
      }
      return res

    } catch (error) {
      console.log('error.statusMessage', error.statusMessage);
    }
  },
  async fetchUser() {
    const { $auth0 } = useNuxtApp()
    const getTokenSilently = await $auth0.getTokenSilently()
    try {
      const res = await $fetch<Promise<ClientInvestorMemberResponse_Success>>('/api/deckmatch_user', {
        headers: {
          authorization: `Bearer ${getTokenSilently}`
        },
        method: 'GET'
      })
      if(res.first_name === '**value not set**') {
        res.first_name = ''
      }
      if(res.last_name === '**value not set**') {
        res.last_name = ''
      }
      this.deckmatchUser.profile = res
      this.setProfileStatus(res)
      this.deckmatchUser.meta = {
        status: 'fetching_success'
      }
      return res
      
    } catch (error) {
      console.log('error.statusMessage', error.statusMessage);
    }
  }
 }
})