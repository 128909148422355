<template>
  <div>
    <NuxtLayout>
      <div class="flex flex-col items-center gap-4 p-8">
        <p> Ops! We can't find the page you were looking for</p>
        <FormKit
          type="button"
          @click.prevent="handleError"
        >
          Go back to 
        </FormKit>
      </div>
    </NuxtLayout>
  </div>
</template>

<script setup lang="ts">
const {error} = defineProps<{error: any}>()

const handleError = async () => {
  await clearError({redirect:'/'})

}
onBeforeRouteLeave(async () => {
  await clearError()
})

</script>
