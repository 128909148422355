<template>
  <NuxtLayout>
    <NuxtErrorBoundary>
      <ul
        v-auto-animate 
        class="fixed top-0 right-0 z-40 flex flex-col gap-2 mt-4 mr-4 w-[350px] max-w-full"
      >
        <ToastComponent
          v-for="toast in toasts"
          :key="toast.id"
          :toast="toast"
        />
      </ul>
      <!-- </TransitionGroup> -->
      <NuxtPage />
      
      <template #error="{error}">
        {{ error }}
      </template>
    </NuxtErrorBoundary>
  </NuxtLayout>
</template>
<script setup lang="ts">
import { useToastStore } from '@/stores/toastStore';

const toastStore = useToastStore();

const toasts = computed(() => toastStore.getToasts);
</script>
