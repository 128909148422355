import { useDeckmatchUserStore } from '@/stores/deckmatchUser';

function isUpdatedAtAfterNoonOnLaunchDay(updatedAt: string | null | undefined): boolean {
  if(!updatedAt) return false;
  // Parse the updated_at date
  const updatedDate = new Date(updatedAt);

  // UTC noon at launch day without adjusting for local timezone
  const noonAtLaunchDay = new Date('2024-03-14T12:00:00.000Z');
  
  // Compare the updated_at time with UTC noon at launch day
  return updatedDate > noonAtLaunchDay;
}

export default defineNuxtRouteMiddleware((to, from) => {
  const { authOrigin } = useRuntimeConfig().public

  const returnTo = useCookie('returnTo', {
    path: '/',
    maxAge: 60 * 60 * 24 * 7,
    sameSite: 'strict',
    secure: true,
  });

  const hasBeenOnBoarded = useCookie('hasBeenOnBoarded', {
    path: '/',
    maxAge: 60 * 60 * 24 * 7 * 4 * 3, // 3 months
    sameSite: 'strict',
    secure: true,
  });

  // hasBeenOnBoarded.value = hasBeenOnBoarded.value === 'true' ? 'true' : 'false';
  
  const { $auth0 } = useNuxtApp();
  
  const deckmatchUser = useDeckmatchUserStore();
  
  return $auth0.isAuthenticated().then(async (isAuthenticated) => {
    if (to.path === '/login' || to.path === '/no_access' || to.path === '/verify_email' || to.path.includes('signup')) {
      return;
    }

    // will probably not happen
    if (to.path === '/authorize' && to.query.error === 'access_denied') {
        return navigateTo('/no_access');
    }
   
    if (!isAuthenticated) {
      
      await nextTick(async () => {
        try {
          await $auth0.handleRedirectCallback();
          
        } catch (error) {
          await $auth0.loginWithRedirect();
          
        }
      })
    }
   
    const { email } = deckmatchUser.getUserInfo;

    if (!email) {
      try {
        await deckmatchUser.fetchUser();
      } catch (error) {
        if (error.statusCode === 404 ) {
          
          const user = await $auth0.getUser()
          const returnToError = user?.email ? `${authOrigin}/no_access?user=${user.email}` : `${authOrigin}/no_access`
          await $auth0.logout({
            logoutParams: {
              returnTo:returnToError,
            },
          });
          return
        }
      }
    }
    const tokenClaims = await $auth0.getIdTokenClaims()

    if(to.path !== '/authorize' ) {
      
      if(tokenClaims && tokenClaims.need_to_verify_email) {
        return navigateTo('/verify_email')
      // Note: when we launch this already logged in users will be redirected to signup
      //---------------------------------------------------------------- hasBeenOnBoarded.value !== 'true' does not work as it renders a boolean and not a string even though the TS type is string
      } else if((tokenClaims && tokenClaims.custom_logins_count <= 1) && hasBeenOnBoarded.value !== true) { // First time login after launch day
        return navigateTo('/signup')
      } else if(to.path !== '/') { // continue
        returnTo.value = to.path
      
      } else if(isAuthenticated) {
        returnTo.value = '/'
      }
    }
    if(to.path === '/authorize' ) {
      return navigateTo(returnTo.value || '/')
    }
    return;
  });
});
