
const buttonVariants = (node) => {
  if (node.props.type === 'button' || node.props.type === 'submit') {

    
    node.addProps(['variant', 'size', 'fullWidth']);
    
    node.props.inputClass = (reactiveNode) => ({
      ['button-regular']: node.props.variant === 'button-regular',
      ['submit-button']: node.props.variant === 'button-submit',
      'py-[10px] px-4 text-base gap-2': !reactiveNode.props.size,
      'py-[4px] px-[6px] text-xs gap-1': reactiveNode.props.size === 'xs',
      'py-[6px] px-[10px] text-sm gap-2': reactiveNode.props.size === 'sm',
      'py-[10px] px-[18px] text-lg gap-2': reactiveNode.props.size === 'lg',
      'py-3 px-5 text-lg gap-2': reactiveNode.props.size === 'xl',
      'py-4 px-7 text-lg gap-3': reactiveNode.props.size === '2xl',
      'w-full justify-center': reactiveNode.props.fullWidth,
      'bg-primary-600 text-white border-primary-600 focus-visible:ring-primary-700 formkit-disabled:opacity-75 formkit-loading:opacity-[100%_!important] ': !reactiveNode.props.variant || reactiveNode.props.variant === 'primary',
      'bg-primary-600 text-white border-primary-600 focus-visible:ring-primary-700 focus-visible:ring-offset-2 outline-none': !reactiveNode.props.variant,
      'bg-white border-gray-300 text-gray-700 shadow-sm focus-visible:ring-gray-700 hover:bg-gray-50 active:bg-gray-100 outline-none focus-visible:ring-offset-2 ': reactiveNode.props.variant === 'secondaryGray',
      'bg-primary-50 border-primary-50 active:border-primary-100 hover:border-primary-100 text-primary-700  focus-visible:ring-primary-700 focus-visible:ring hover:bg-primary-100 active:bg-primary-100 outline-none focus-visible:ring-offset-2': reactiveNode.props.variant === 'secondaryPrimary',
      'bg-error-600 border-error-600 text-white shadow-sm focus-visible:ring-error-600 hover:bg-error-700 active:bg-error-700  focus-visible:ring-2 focus-visible:ring-offset-2 outline-none': reactiveNode.props.variant === 'danger',
      '$reset outline-none': reactiveNode.props.variant === 'custom'
    });
  }
};

export { buttonVariants };