// formkit.config.js
import { defineFormKitConfig } from '@formkit/vue'

import { createProPlugin, inputs } from '@formkit/pro'
import { createAutoAnimatePlugin } from '@formkit/addons'

// icons
import { genesisIcons } from '@formkit/icons'

// plugins
import { createLoadingSpinnerPlugin } from '@/formkit/createLoadingSpinnerPlugin'
import { buttonVariants }  from '~/components/form/buttons/buttonVariants'

// custom components
import { checkbox } from '@/components/form/checkbox'
import { textarea } from '@/components/form/textarea'

import { generateClasses } from '@formkit/themes'
import myTailwindTheme from '@/formkit/formkit-base-theme' // change to your theme's path

export default defineFormKitConfig(() => {

  // plugin
  const pro = createProPlugin('fk-7a63b563864', inputs)

  return {
    plugins: [
      pro,
      createAutoAnimatePlugin(),
      createLoadingSpinnerPlugin(),
      buttonVariants
    ],
    icons: {
      ...genesisIcons,
      check: `<svg   viewBox="0 0 12 12" fill="none">
      <path d="M10 3L4.5 8.5L2 6" stroke="currentColor" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/>
      </svg>`,
      check_button: `<svg  fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor">
      <path stroke-linecap="round" stroke-linejoin="round" d="m4.5 12.75 6 6 9-13.5" />
    </svg>
    `,
      copy: `<svg  viewBox="0 0 25 24" fill="none">
      <path d="M11 2.0028C10.3249 2.01194 9.9197 2.05103 9.59202 2.21799C9.21569 2.40973 8.90973 2.71569 8.71799 3.09202C8.55103 3.4197 8.51194 3.82495 8.5028 4.5M20 2.0028C20.6751 2.01194 21.0803 2.05103 21.408 2.21799C21.7843 2.40973 22.0903 2.71569 22.282 3.09202C22.449 3.4197 22.4881 3.82494 22.4972 4.49999M22.4972 13.5C22.4881 14.175 22.449 14.5803 22.282 14.908C22.0903 15.2843 21.7843 15.5903 21.408 15.782C21.0803 15.949 20.6751 15.9881 20 15.9972M22.5 7.99999V9.99999M14.5001 2H16.5M5.7 22H13.3C14.4201 22 14.9802 22 15.408 21.782C15.7843 21.5903 16.0903 21.2843 16.282 20.908C16.5 20.4802 16.5 19.9201 16.5 18.8V11.2C16.5 10.0799 16.5 9.51984 16.282 9.09202C16.0903 8.71569 15.7843 8.40973 15.408 8.21799C14.9802 8 14.4201 8 13.3 8H5.7C4.5799 8 4.01984 8 3.59202 8.21799C3.21569 8.40973 2.90973 8.71569 2.71799 9.09202C2.5 9.51984 2.5 10.0799 2.5 11.2V18.8C2.5 19.9201 2.5 20.4802 2.71799 20.908C2.90973 21.2843 3.21569 21.5903 3.59202 21.782C4.01984 22 4.57989 22 5.7 22Z" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>`,
    arrowEnd: `<svg  viewBox="0 0 16 10" fill="none">
    <g clip-path="url(#clip0_741_155719)">
    <path d="M12.5 5.5H3.5C3.22 5.5 3 5.28 3 5C3 4.72 3.22 4.5 3.5 4.5H12.5C12.78 4.5 13 4.72 13 5C13 5.28 12.78 5.5 12.5 5.5Z" fill="currentColor"/>
    <path d="M15 8.15L15 1.85C15 1.654 15.22 1.5 15.5 1.5C15.78 1.5 16 1.654 16 1.85L16 8.15C16 8.346 15.78 8.5 15.5 8.5C15.22 8.5 15 8.346 15 8.15Z" fill="currentColor"/>
    <path d="M10 9.00047C9.87 9.00047 9.74 8.95047 9.65 8.85047C9.45 8.65047 9.45 8.34047 9.65 8.14047L12.8 4.99047L9.65 1.84047C9.45 1.64047 9.45 1.33047 9.65 1.13047C9.85 0.930469 10.16 0.930469 10.36 1.13047L13.86 4.63047C14.06 4.83047 14.06 5.14047 13.86 5.34047L10.36 8.84047C10.26 8.94047 10.13 8.99047 10.01 8.99047L10 9.00047Z" fill="currentColor"/>
    </g>
    </svg>`,
    arrowStart: `<svg  viewBox="0 0 16 10" fill="none">
    <g clip-path="url(#clip0_741_155713)">
      <path d="M12.5 5.5H3.5C3.22 5.5 3 5.28 3 5C3 4.72 3.22 4.5 3.5 4.5H12.5C12.78 4.5 13 4.72 13 5C13 5.28 12.78 5.5 12.5 5.5Z" fill="currentColor"/>
      <path d="M-2.90681e-07 8.15977L-1.5299e-08 1.85977C-6.73155e-09 1.66377 0.22 1.50977 0.5 1.50977C0.78 1.50977 1 1.66377 1 1.85977L1 8.15977C1 8.35577 0.78 8.50977 0.5 8.50977C0.22 8.50977 -2.99248e-07 8.35577 -2.90681e-07 8.15977Z" fill="currentColor"/>
      <path d="M6 9C5.87 9 5.74 8.95 5.65 8.85L2.15 5.35C1.95 5.15 1.95 4.84 2.15 4.64L5.65 1.15C5.85 0.95 6.16 0.95 6.36 1.15C6.56 1.35 6.56 1.66 6.36 1.86L3.21 5.01L6.36 8.16C6.56 8.36 6.56 8.67 6.36 8.87C6.26 8.97 6.13 9.02 6.01 9.02L6 9Z" fill="currentColor"/>
    </g>
  </svg>`,
    download: `<svg  viewBox="0 0 20 20" fill="none">
    <path d="M3.33341 13.5352C2.32843 12.8625 1.66675 11.7168 1.66675 10.4167C1.66675 8.46369 3.15967 6.85941 5.06653 6.68281C5.45659 4.31011 7.51695 2.5 10.0001 2.5C12.4832 2.5 14.5436 4.31011 14.9336 6.68281C16.8405 6.85941 18.3334 8.46369 18.3334 10.4167C18.3334 11.7168 17.6717 12.8625 16.6667 13.5352M6.66675 14.1667L10.0001 17.5M10.0001 17.5L13.3334 14.1667M10.0001 17.5V10" stroke="#344054" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>`,
    upload: `<svg viewBox="0 0 20 20" fill="none">
    <path d="M3.33366 13.5352C2.32867 12.8625 1.66699 11.7168 1.66699 10.4167C1.66699 8.46369 3.15992 6.85941 5.06678 6.68281C5.45684 4.31011 7.5172 2.5 10.0003 2.5C12.4835 2.5 14.5438 4.31011 14.9339 6.68281C16.8407 6.85941 18.3337 8.46369 18.3337 10.4167C18.3337 11.7168 17.672 12.8625 16.667 13.5352M6.66699 13.3333L10.0003 10M10.0003 10L13.3337 13.3333M10.0003 10V17.5" stroke="#344054" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>`,
    chevron_up: `<svg  fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" data-slot="icon" >
    <path stroke-linecap="round" stroke-linejoin="round" d="m4.5 15.75 7.5-7.5 7.5 7.5" />
  </svg>
  `,
    chevron_down: `<svg  fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" data-slot="icon" >
    <path stroke-linecap="round" stroke-linejoin="round" d="m4.5 15.75 7.5-7.5 7.5 7.5" />
  </svg>
  `,
    share: `
    <div class="p-px -mt-1">
      <svg   viewBox="0 0 18 16" fill="none">
      <path d="M15.8261 8.50617C16.0296 8.3318 16.1313 8.24461 16.1686 8.14086C16.2013 8.0498 16.2013 7.9502 16.1686 7.85914C16.1313 7.75539 16.0296 7.6682 15.8261 7.49383L8.76719 1.44331C8.417 1.14315 8.24191 0.993063 8.09367 0.989386C7.96483 0.986191 7.84177 1.04279 7.76035 1.14269C7.66667 1.25764 7.66667 1.48825 7.66667 1.94948V5.52886C5.88777 5.84007 4.25966 6.74146 3.04976 8.09489C1.73069 9.57043 1.00103 11.48 1 13.4591V13.9691C1.87445 12.9157 2.96626 12.0638 4.20063 11.4716C5.28891 10.9495 6.46535 10.6403 7.66667 10.5588V14.0505C7.66667 14.5117 7.66667 14.7424 7.76035 14.8573C7.84177 14.9572 7.96483 15.0138 8.09367 15.0106C8.24191 15.0069 8.417 14.8569 8.76719 14.5567L15.8261 8.50617Z" stroke="white" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round"/>
      </svg>
    </div>
    `,
    mail: `
    <svg  fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" >
  <path stroke-linecap="round" stroke-linejoin="round" d="M21.75 6.75v10.5a2.25 2.25 0 0 1-2.25 2.25h-15a2.25 2.25 0 0 1-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0 0 19.5 4.5h-15a2.25 2.25 0 0 0-2.25 2.25m19.5 0v.243a2.25 2.25 0 0 1-1.07 1.916l-7.5 4.615a2.25 2.25 0 0 1-2.36 0L3.32 8.91a2.25 2.25 0 0 1-1.07-1.916V6.75" />
</svg>

    `
    },
    inputs: { checkbox, textarea },
    config: {
      classes: generateClasses(myTailwindTheme),
    },
  }
})